import "./ExploreSearchView.css";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";

import Loader from "components/misc/Loader/Loader";
import UserSquare from "components/misc/UserSquare/UserSquare";

import { useNavigate, NavLink } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import { SignedIn, useUser } from "@clerk/clerk-react";

import { Career, CareerSearchOptions, CareerWithChildren } from "services/datatypes";
import api from "services/api";
import routes from "services/routes";

function ExploreSearchView() {
  const navigate = useNavigate();
  let { isSignedIn, user, isLoaded } = useUser();

  const [searchOpen, setSearchOpen] = useState(false);
  const [loadingLikedCareers, setLoadingLikedCareers] = useState(false);
  const [likedCareers, setLikedCareers] = useState<Array<Career>>([]);
  const [careers, setCareers] = useState<Array<Career>>([]);
  const careersWithChildren = useRef<Array<CareerWithChildren>>([]);

  const [careerResults, setCareerResults] = useState<Array<Career>>([]);
  const [careerResultsLoading, setCareerResultsLoading] = useState<
    boolean | null
  >(null);

  const [searchOptions, setSearchOptions] = useState<CareerSearchOptions>(
    {} as CareerSearchOptions
  );

  useEffect(() => {
    if (isSignedIn == true) {
      setLoadingLikedCareers(true);
      api.getLikedCareers().then((res) => {
        setLikedCareers(res);
        setLoadingLikedCareers(false);
      });
    }

    api.getCareers(null, true).then((res: Career[]) => {
      setCareers(res);

      careersWithChildren.current = []
      for (const career of res) {
        if (career.parent_career === null) {
          careersWithChildren.current.push({
            id: career.id,
            name: career.name,
            child_careers: res.filter((childCareer) => {return childCareer.parent_career === career.id})
          })
        }
      }
    });
  }, [isSignedIn]);

  function getOrderedSearchedCareers(searchValue: string = ""): Career[] {
    const orderedCareers: Career[] = []

    for (const careerWithChildren of careersWithChildren.current) {
      // if parent or any child includes search value, then parents and all children are included
      if (
          careerWithChildren.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          careerWithChildren.child_careers.some((childCareer) => {return childCareer.name.toLowerCase().includes(searchValue.toLowerCase())})
      ) {
        // add parent career
        orderedCareers.push({
          id: careerWithChildren.id,
          name: careerWithChildren.name,
          parent_career: null,
        })

        // add all children careers
        orderedCareers.push(...careerWithChildren.child_careers)
      }
    }

    return orderedCareers;
  }

  function boldString(str: string, subString: string) {
    if (subString === "") {
      return str
    }

    const strRegExp = new RegExp(subString, 'gi')

    const replaceString = str.replace(strRegExp, match => `<span class="HighlightedSearchText">${match}</span>`)
    return `<span>${replaceString}</span>`
  }

  // @ts-ignore
  return (
    <div className="explore-search-view">
      <h1 className="section-title">Get Answers</h1>

      {isLoaded && (
        <>
          <SignedIn>
            <div className="liked-careers anim-appear">
              <div className="title">
                <FavoriteIcon />
                <div className="title-text">Liked careers</div>
              </div>
              <div className="career-list">
                {loadingLikedCareers === true && (
                  <div style={{ textAlign: "center" }}>
                    <Loader />
                  </div>
                )}

                {loadingLikedCareers === false && (
                  <>
                    {likedCareers.length == 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          opacity: "0.6",
                        }}
                      >
                        No liked careers yet
                      </div>
                    )}

                    {likedCareers.map((career) => (
                      <NavLink
                        to={
                          routes.explore.short +
                          "/" +
                          encodeURIComponent(career.name)
                        }
                        className="career anim-appear"
                      >
                        {career.name}
                      </NavLink>
                    ))}
                  </>
                )}
              </div>
            </div>
          </SignedIn>

          <div className="search-bar">
            <div className="bar">
              <Autocomplete
                  freeSolo
                  forcePopupIcon
                  disableClearable
                  open={searchOpen}
                  onOpen={() => {
                    setSearchOpen(true);
                  }}
                  onClose={() => {
                    setSearchOpen(false);
                  }}
                  value={searchOptions.name_contains ?? ""}
                  options={getOrderedSearchedCareers()}
                  filterOptions={(options: Career[], state) => {
                    return getOrderedSearchedCareers(state.inputValue)
                  }}
                  getOptionLabel={(option) => {
                    // Ensure the option is always a string
                    if (typeof option === "string") {
                      return option;
                    } else if (option && typeof option.name === "string") {
                      return option.name;
                    } else {
                      return "";
                    }
                  }}
                  renderOption={(props, option,  { inputValue }) => (
                      <li
                          {...props}
                          style={{
                            marginLeft: option.parent_career === null ? "0" : "25px",
                            fontStyle: option.parent_career === null ? "normal" : "italic",
                            borderLeft: option.parent_career === null ? "0" : "1px solid #e4e4e4",
                          }}
                          dangerouslySetInnerHTML={{__html: boldString(option.name, inputValue)}}
                      />
                  )}
                  onChange={(_, newValue) => {
                    if (typeof newValue !== "string") {
                      if (newValue.parent_career !== null) {
                        const parentCareer = careers.find((career) => {return career.id === newValue.parent_career})
                        if (parentCareer) {
                          navigate(`${encodeURIComponent(parentCareer.name)}?searchedChildCareer=${newValue.name}`)
                        }
                      } else {
                        navigate(encodeURIComponent(newValue.name))
                      }
                    }
                  }}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          placeholder="Select or type career"
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    style={{ marginLeft: "2px", marginRight: "-5px" }}
                                >
                                  <SearchIcon />
                                </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setSearchOptions({
                              ...searchOptions,
                              name_contains: e.target.value,
                            });
                          }}
                      />
                  )}
              />
            </div>
          </div>

          {/* OLD SEARCH FUNCTIONALITY (hopefully it will be brought back at some point) */}
          {/* <div
            className={
              "search-options box box-minimisable anim-appear" +
              (searchOptionMinimised == true
                ? " minimised"
                : searchOptionMinimised == false
                ? " maximised"
                : "")
            }
          >
            <div
              className="box-header"
              onClick={() => {
                setSearchOptionMinimised(!searchOptionMinimised);
              }}
            >
              <div className="title-icon">
                <TuneIcon />
              </div>
              <div className="title">
                Search options (fill in at least one field below)
              </div>
              <div className="flex-filler"></div>
              <div className="minimise-toggle">
                <KeyboardArrowUpIcon fontSize="large" />
              </div>
            </div>

            <div className="box-minimise">
              <div className="box-body">
                <Autocomplete
                  freeSolo
                  forcePopupIcon
                  disableClearable
                  value={searchOptions.name_contains ?? ""}
                  options={careers.map((career) => {
                    return career.name;
                  })}
                  onChange={(_, newValue) => {
                    setSearchOptions({
                      ...searchOptions,
                      name_contains: newValue || "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select or type"
                      label="Career name"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: "8px" }}
                          >
                            <BusinessCenterIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setSearchOptions({
                          ...searchOptions,
                          name_contains: e.target.value,
                        });
                      }}
                    />
                  )}
                />

                <TextField
                  fullWidth
                  className="mt-4"
                  label="What I love"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FavoriteIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      love_text: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  className="mt-4"
                  label="What I don't love"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HeartBrokenIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      not_love_text: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  className="mt-4"
                  label="What skills and qualities I have"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmojiEventsIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      skills_text: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="box-footer">
                <div
                  className={
                    "button" + (canSubmit() == false ? " disabled" : "")
                  }
                  onClick={submit}
                >
                  Search
                </div>
              </div>
            </div>
          </div> */}

          {careerResultsLoading == true && (
            <>
              <div style={{ textAlign: "center", marginTop: "60px" }}>
                <Loader />
              </div>
            </>
          )}

          {careerResultsLoading == false && (
            <>
              {careerResults.length == 0 && (
                <div style={{ textAlign: "center" }}>
                  No matching careers yet
                </div>
              )}

              {careerResults.map((career) => (
                <NavLink key={career.name} to={encodeURIComponent(career.name)}>
                  <div className="career-result">{career.name}</div>
                </NavLink>
              ))}
            </>
          )}

          <div className="people-container anim-appear">
            <h2>Featured</h2>
            <div className="people">
              {/* Me (for local dev) */}
              {/* <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" /> */}
              {/* <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" /> */}
              {/* <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" /> */}
              {/* <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" /> */}

              {/* Warren Buffett */}
              {/* <UserSquare userId="user_2YIjKvWSv8WGLCJCJJn3GqVxppi" /> */}
              {/* Roger Federer */}
              {/* <UserSquare userId="user_2dP6vJZBnE7iYJGPYk7fbKqOE2H" /> */}
              {/* Bill Gates */}
              {/* <UserSquare userId="user_2YIiW77x9K4xLhhyYaNRk06eR1V" /> */}
              {/* Indra Nooyi */}
              {/* <UserSquare userId="user_2YItlLbN99EkVBTu4DOyPwnoCQR" /> */}

              {/* Monisha D'souza */}
              <UserSquare userId="user_2dP6hJJ1DVFSskXEKR8NVO4gi9Q" title="Entrepreneur-Proprietor" />
              {/* Sandeep Engineer */}
              <UserSquare userId="user_2Z8ELbFAhX4ug3PQcPNg63WWjl3" title="Entrepreneur-Founder" />
              {/* Bimal Patel */}
              <UserSquare userId="user_2dP6YaLGf9G4w6Bf9cjqQtk4u5I" title="Architect" />
              {/* Nirupama Rao */}
              <UserSquare userId="user_2dP6pvvDpOZcWz8n3R1vmDKfw90" title="Diplomat" />
              {/* Mallika Sarabhai */}
              <UserSquare userId="user_2Z8EB8mzZYO8bjzeDVklQGFTKAa" title="Communicator-Dancer" />
              {/* Geet Sethi */}
              <UserSquare userId="user_2Z8Dyr8f2BlvjKIEkQQEgyiJe1g" title="Sportsperson-Billiards and Snooker" />
              {/* Shyamai Shodhan */}
              <UserSquare userId="user_2Z8EXnueh0Hdlp1u0X1yZE6PfUD" title="Fashion Designers" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ExploreSearchView;
