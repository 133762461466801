import "./AboutView.css";

function AboutView() {
  return (
    <div className="about-view anim-appear">
      <div className="home-section">
        <h1 className="section-title">About</h1>

        <h2>Did you know</h2>
        <p>
          People typically spend more time working in their careers than on
          eating, drinking, and personal care combined.
        </p>
      </div>

      <div className="home-section">
        <h2>Don't settle</h2>
        <p>You will find the career of your dreams.</p>
      </div>

      <h2>Get answers to what others:</h2>
      <ol style={{ marginTop: "0px" }}>
        <li>Love about their career.</li>
        <li>Do not love about their career.</li>
        <li>
          Consider to be the skills and qualities that make them thrive in their
          career.
        </li>
      </ol>
    </div>
  );
}

export default AboutView;
