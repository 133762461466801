import "./ExploreCareerView.css";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Swal from 'sweetalert2';

import CareerExperienceCard from "components/misc/CareerExperienceCard/CareerExperienceCard";
import Loader from "components/misc/Loader/Loader";

import { useEffect, useState } from "react";
import {useParams, useSearchParams} from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import api from "services/api";
import { Career, CareerExperience } from "services/datatypes";
import routes from "services/routes";

function ExploreCareerView() {
  let { careerName } = useParams();
  let { isSignedIn } = useUser();

  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const [careerIsLiked, setCareerIsLiked] = useState<boolean | null>(null);
  const [likedCareers, setLikedCareers] = useState<Array<Career>>([]);

  const [searchedChildCareer, setSearchedChildCareer] = useState<string | null>(null);

  const [careerExperiencesLoading, setCareerExperiencesLoading] =
    useState(false);
  const [careerExperiences, setCareerExperiences] = useState<
    Array<CareerExperience>
  >([]);

  useEffect(() => {
    if (careerName) {
      setCareerExperiencesLoading(true);
      api.getCareerExperiences(careerName).then((res) => {
        setCareerExperiences(res);
        setCareerExperiencesLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    const tempSearchedChildCareer = queryParameters.get("searchedChildCareer")

    setSearchedChildCareer(tempSearchedChildCareer);
  }, [queryParameters]);

  useEffect(() => {
    if (isSignedIn == true) {
      api.getLikedCareers().then((res) => {
        setLikedCareers(res);

        // check if career is liked or not
        var career_is_liked = false;
        for (const likedCareer of res) {
          if (likedCareer.name == careerName) {
            career_is_liked = true;
          }
        }
        setCareerIsLiked(career_is_liked);
      });
    }
  }, [isSignedIn]);

  function toggleLikeCareer() {
    if (isSignedIn === false) {
      Swal.fire({
        title: 'Sign In',
        text: 'Please sign in to like this Career',
        icon: 'info',
        confirmButtonText: 'Sign In',
        showCancelButton: true,
        cancelButtonText: "Cancel",
      }).then((value) => {
        if (value.isConfirmed) {
          navigate(routes.authenticate.short+`?afterSignInUrl=${window.location.href}`)
        }
      })
    }

    if (careerName) {
      if (careerIsLiked == false) {
        api.likeCareer(careerName).then(() => {
          setCareerIsLiked(true);
        });
      } else if (careerIsLiked == true) {
        api.unlikeCareer(careerName).then(() => {
          setCareerIsLiked(false);
        });
      }
    }
  }

  return (
    <div className="explore-career-view">
      {searchedChildCareer !== null && <>
        <div className="page-title">
          <h1>
            {searchedChildCareer}
          </h1>
        </div>

        {careerExperiencesLoading == true && (
            <>
              <div style={{ textAlign: "center", marginTop: "60px" }}>
                <Loader />
              </div>
            </>
        )}

        {careerExperiencesLoading == false && (
          <>
            {careerExperiences.length == 0 && (
                <div style={{ textAlign: "center", margin: "60px 0" }}>
                No answers for this career yet
              </div>
            )}

            {careerExperiences
              .filter((careerExperience) => { return careerExperience.secondary_career?.name === searchedChildCareer })
              .map((careerExperience) => (
                <CareerExperienceCard
                    key={careerExperience.id}
                    careerExperience={careerExperience}
                    showCareerName={false}
                />
              ))
            }
          </>
        )}
      </>}

      <div className="page-title">
        <h1>
          {searchedChildCareer === null ? careerName : <>More answers for <b>{careerName}</b> careers</>}
        </h1>
        <div className="flex-filler"></div>
        {searchedChildCareer === null && (isSignedIn == false || careerIsLiked != null) && (
            <div className="button anim-appear" onClick={toggleLikeCareer}>
              <div className="button-inner">
                {(isSignedIn == false || careerIsLiked == false) ? (
                    <>
                      <FavoriteBorderIcon/>
                      <div className="button-text">Like Career</div>
                    </>
                ) : (
                    <>
                      <FavoriteIcon/>
                      <div className="button-text">Unlike Career</div>
                    </>
                )}
              </div>
            </div>
        )}
      </div>

      {careerExperiencesLoading == true && (
        <>
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            <Loader />
          </div>
        </>
      )}

      {careerExperiencesLoading == false && (
        <>
          {careerExperiences.length == 0 && (
            <div style={{ textAlign: "center", margin: "60px 0" }}>
              No answers for this career yet
            </div>
          )}

          {careerExperiences
              .filter((careerExperience) => { return searchedChildCareer === null || careerExperience.secondary_career?.name !== searchedChildCareer })
              .map((careerExperience) => (
                <CareerExperienceCard
                  key={careerExperience.id}
                  careerExperience={careerExperience}
                  showCareerName={false}
                />
              ))
          }
        </>
      )}
    </div>
  );
}

export default ExploreCareerView;
